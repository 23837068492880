import React, { useState } from 'react';
import './adminProfile.scss'; // Ensure this path is correct

const AdminProfile = () => {
  const [name, setName] = useState('Admin Name'); // Replace with actual admin name from state or props
  const [phone, setPhone] = useState(''); // Admin's phone number
  const [email, setEmail] = useState('admin@example.com'); // Admin's email (could be non-editable)
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null); // Admin's profile image
  const [message, setMessage] = useState('');

  const handleImageChange = (event) => {
    setProfileImage(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Validate if password and confirmPassword match
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }

    // Mock update logic (can integrate with API or backend logic)
    console.log('Updating admin profile:', { name, phone, email, password, profileImage });
    setMessage('Profile updated successfully!');
    
    // Reset form fields if necessary
    setPassword('');
    setConfirmPassword('');
  };

  return (
    <div className="container">
      <h1>Admin Profile</h1>
      <form onSubmit={handleSubmit} className="form">
      <label className="label">Profile Image:</label>
      {profileImage && (
          <div className="imagePreview">
            <img
              src={URL.createObjectURL(profileImage)}
              alt="Profile Preview"
              className="previewImage"
            />
          </div>
        )}
        <input
          type="file"
          onChange={handleImageChange}
          accept="image/*"
          className="fileInput"
        />
        
        <label className="label">Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your name"
          className="input"
        />

        <label className="label">Phone:</label>
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Enter your phone number"
          className="input"
        />

        <label className="label">Email:</label>
        <input
          type="email"
          value={email}
          
          placeholder="admin@example.com"
          className="input"
        />

      

        <label className="label">New Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter new password"
          className="input"
        />

        <label className="label">Confirm New Password:</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          className="input"
        />

        <button type="submit" className="submitButton">Update Profile</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default AdminProfile;
