// NewsUpload.js
import React, { useState } from 'react';
import './news&blogs.scss'; // Ensure this path is correct

const NewsUpload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Form validation
    if (!title || !description || !date || !image) {
      setMessage('Please fill in all fields and upload an image.');
      return;
    }

    // Mock form submission (replace with actual API logic)
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('date', date);
    formData.append('image', image);

    console.log('Submitting blog:', { title, description, date, image });

    setMessage('Blog post uploaded successfully!');

    // Clear the form
    setTitle('');
    setDescription('');
    setDate('');
    setImage(null);
  };

  return (
    <div className="news-upload-container">
      <h1>Upload News & Blogs</h1>
      <form onSubmit={handleSubmit} className="news-upload-form">
      <label className="label">Upload Image:</label>
        
      {image && (
          <div className="imagePreview">
            <img
              src={URL.createObjectURL(image)}
              alt="Preview"
              className="previewImage"
            />
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="fileInput"
        />

        
        <label className="label">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter blog or news title"
          className="input"
        />

        <label className="label">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Write the content here"
          className="textarea"
        />

        <label className="label">Date:</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="input"
        />

       

        <button type="submit" className="submitButton">Upload</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default NewsUpload;
