import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaBars,
  FaHome,
  FaChartLine,
  FaUserPlus,
  FaUpload,
  FaClipboardList,
  FaUser,
  FaMicrophone,
  FaNewspaper,
} from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import './sidebar.scss';
import Login from '../Login/Login';
import Analytics from '../side-manu/analytics/Analytics';
import Addemployee from '../side-manu/add employye/Addemployee';
import Upload from '../side-manu/upload content/Upload';
import AnnouncementUpload from '../side-manu/anouncment/Announcement';
import AdminProfile from '../side-manu/adminProfile/AdminProfile';
import NewsUpload from '../side-manu/news&blogs/news&blogs';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const navigate = useNavigate();

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };
  const handleHome = (event) => {
    event.preventDefault();
    navigate("/Sidebar");
  };
  const handlelogin = (event) => {
    event.preventDefault();
    navigate("/Login");
  };
  const renderContent = () => {
    switch (activeMenu) {
      
      case 'Analytics':
        return <Analytics />;
      case 'Add Employee':
        return <Addemployee />;
      case 'Upload Content':
        return <Upload/>
      case 'Requests':
        return <AnnouncementUpload/>
      case 'News & blogs':
        return <NewsUpload />
        case 'Profile':
        return <AdminProfile />
      default:
        return;
    }
  };

  return (
    <div className="sidebar-layout">
      {/* Header */}
      <header className="header1">
        <FaBars className="hamburger-icon" onClick={handleCollapse} />
        <h1 className="header-title">Admin Panel</h1>
      </header>

      <div className="layout">
        {/* Sidebar */}
        <aside className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
          <ul className="sidebar-menu">
            <li onClick={() => handleMenuClick('Analytics')}>
              <FaChartLine />
              {!isCollapsed && <span>Analytics</span>}
            </li>
            <li onClick={() => handleMenuClick('Add Employee')}>
              <FaUserPlus />
              {!isCollapsed && <span>Add Employee</span>}
            </li>
            <li onClick={() => handleMenuClick('Upload Content')}>
              <FaUpload />
              {!isCollapsed && <span>Upload File</span>}
            </li>
            <li onClick={() => handleMenuClick('Requests')}>
              <FaMicrophone />
              {!isCollapsed && <span>Anouncment</span>}
            </li>
            <li onClick={() => handleMenuClick('News & blogs')}>
              <FaNewspaper />
              {!isCollapsed && <span>News & blogs</span>}
            </li>
            <li onClick={() => handleMenuClick('Profile')}>
              <FaUser />
              {!isCollapsed && <span>Profile</span>}
            </li>
            <li  onClick={handlelogin}>
              <FiLogOut />
              {!isCollapsed && <span>Logout</span>}
            </li>
          </ul>
        </aside>

        {/* Main Content */}
        <main className="main-content">{renderContent()}</main>
      </div>
    </div>
  );
};

export default Sidebar;
