import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // Make sure to import correctly
import Login from '../src/Login/Login';
import Signup from '../src/signup/Signup';
import Sidebar from '../src/sidebar/Sidebar';
import Analytics from '../src/side-manu/analytics/Analytics';
import Addemployee from './side-manu/add employye/Addemployee';
import UploadContent from './side-manu/upload content/Upload';
import AnnouncementUpload from './side-manu/anouncment/Announcement';
import AdminProfile from './side-manu/adminProfile/AdminProfile';


const App = () => {
    return (
        <Router>  {/* Use Router instead of BrowserRouter directly here */}
            <Routes>
                <Route path="/" element={<Login />} /> 
                <Route path="/Signup" element={<Signup />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/Sidebar" element={<Sidebar />} />
                <Route path="/Analytics" element={<Analytics />} />
                <Route path="/Addemployee" element={<Addemployee />} />
                <Route path="/UploadContent" element={<UploadContent />} />
                <Route path="/AnnouncementUpload" element={<AnnouncementUpload />} />
                <Route path="/AdminProfile" element={<AdminProfile />} />

            </Routes>
        </Router>
    );
};

export default App;
