import React, { useState } from 'react';
import './upload.scss'; // Ensure this path is correct

const Upload = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] = useState(''); // State for the date
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Form validation
    if (!title || !description || !date || files.length === 0) {
      setMessage('Please fill in all fields, select a date, and upload at least one file.');
      return;
    }

    // Mock upload logic
    console.log('Uploading content:', { title, description, date, files });
    setMessage('Content uploaded successfully!');

    // Reset form
    setTitle('');
    setDescription('');
    setDate(''); // Reset date
    setFiles([]);
  };

  return (
    <div className="container">
      <h1>Upload File</h1>
      <form onSubmit={handleSubmit} className="form">
        <label className="label">Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter the title of the announcement"
          className="input"
        />

        <label className="label">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Provide a brief description of the announcement"
          className="textarea"
        />

        <label className="label">Date:</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="input" // Reuse the input styles
        />

        <label className="label">Upload Files:</label>
        <input
          type="file"
          multiple
          accept=".pdf,.xls,.xlsx"
          onChange={handleFileChange}
          className="fileInput"
        />

        <div className="filePreview">
          <h4>Selected Files:</h4>
          {files.map(file => (
            <div key={file.name} className="fileItem">
              {file.name}
              <button type="button" onClick={() => handleFileRemove(file.name)} className="removeButton">Remove</button>
            </div>
          ))}
        </div>

        <button type="submit" className="submitButton">Upload</button>

        {message && <div className="message">{message}</div>}
      </form>
    </div>
  );
};

export default Upload;
