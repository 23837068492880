// AddEmployeePage.js
import React, { Component } from 'react';
import './addemployee.scss';

class Addemployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      name: '',
      phone: '',
      email: '',
      employmentDate: '',
      username: '',
      password: ''
    };
  }

  // Handle input change
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  // Handle image change
  handleImageChange = (e) => {
    if (e.target.files[0]) {
      this.setState({
        image: URL.createObjectURL(e.target.files[0])
      });
    }
  };

  // Handle form submit
  handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log(this.state);
  };

  render() {
    const { image, name, phone, email, employmentDate, username, password } = this.state;

    return (
      <div className="add-employee-form-container">
        <h2>Add New Employee</h2>
        <form onSubmit={this.handleSubmit} className="add-employee-form">
          {/* Image Uploader */}
          <div className="form-group">
            <label htmlFor="image">Profile Picture</label>
            {image && <img src={image} alt="Profile Preview" className="profile-preview" />}
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={this.handleImageChange}
            />
          </div>

          {/* Name */}
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={this.handleInputChange}
              placeholder="Enter employee's full name"
              required
            />
          </div>

          {/* Phone Number */}
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={phone}
              onChange={this.handleInputChange}
              placeholder="Enter phone number"
              required
            />
          </div>

          {/* Email */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={this.handleInputChange}
              placeholder="Enter email address"
              required
            />
          </div>

          {/* Date of Employment */}
          <div className="form-group">
            <label htmlFor="employmentDate">Date of Employment</label>
            <input
              type="date"
              id="employmentDate"
              name="employmentDate"
              value={employmentDate}
              onChange={this.handleInputChange}
              required
            />
          </div>

          {/* Username */}
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={this.handleInputChange}
              placeholder="Enter username"
              required
            />
          </div>

          {/* Password */}
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={this.handleInputChange}
              placeholder="Enter password"
              required
            />
          </div>

          {/* Submit Button */}
          <button type="submit" className="submit-btn">Add Employee</button>
        </form>
      </div>
    );
  }
}

export default Addemployee;
