import React from 'react'
import './analytics.scss'



const Analytics = () => {
  return (
    <div className='star'>Analytics</div>
  )
}

export default Analytics